import React from "react";
import "./App.css";
import { createMuiTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Headder from "./componente/home/home"


const theme = createMuiTheme({
  typography: {
    fontFamily: [

      'Alloy-Regular'
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Headder/>
    </div>
    </ThemeProvider>
  );
}

export default App;