import "./home.css";
import swlogodark from "../../imagini/SUNWAVES-LOGO-OFICIAL.png";

const Headder = () => {
  return (
    <div style={{ height: "80vmax" }}>
      <div className="App-header">
        <img class="img2" src={swlogodark} className="App-logo" alt="logo" />
      </div>
      <div className="Menu-title">
        <h2>STAY TUNED FOR MORE</h2>
      </div>
    </div>
  );
};

export default Headder;
